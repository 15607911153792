import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {OperationsService} from '../../services/operations.service';
import {Budget, Credits, CreditsHistory, MsConsumersService, MsServicesGiftService, Offer, Operation} from '@isifid/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GiftService} from '../../services/gift.service';
import {catchError, finalize, forkJoin, Observable, of, switchMap, tap} from 'rxjs';

@Component({
    selector: 'app-operations-bar',
    templateUrl: './operations-bar.component.html'
})

export class OperationsBarComponent implements OnInit, OnChanges, OnDestroy {
    hasEnoughCredits: boolean;
    budget: Budget;
    credit: Credits;
    offers: Array<Offer> = [];
    operations: Array<Operation> = [];
    selectedOffer: Offer;
    selectedOperation: Operation;
    isLoadingOffers: boolean = true;
    hasExpiredInAmount: boolean;
    private creditsHistories: CreditsHistory[] = [];

    // Send back the offer and the operation
    @Output() offerEvent = new EventEmitter<Offer>();
    @Output() operationEvent = new EventEmitter<Operation>();
    @Output() canRewardWithCreditEvent = new EventEmitter<boolean>();
    // Get the operationId to preload
    @Input() selectedOperationId: number;
    // Get the offerId to preload
    @Input() selectedOfferId: number;

    constructor(
        private readonly giftService: GiftService,
        private readonly msConsumersService: MsConsumersService,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly operationsService: OperationsService,
        private readonly _snackBar: MatSnackBar
    ) {
    }
    ngOnInit() {
        this.offerEvent.subscribe({next: (offer) => this.selectedOffer = offer});
    }

    // ngOnChanges to detect change in consumer input
    ngOnChanges(e) {
        if (e.consumer && !e.selectedOperation) this.resetOperationsStatus();
        this.init();
    }

    ngOnDestroy() {
        this.resetOperationsStatus();
    }

    init() {
        this.operations = this.operationsService.getOperations();

        // Select the first operation if no operation selected
        if (!this.selectedOperationId && this.operations[0]) {
            this.selectedOperation = this.operations[0];
            this.selectedOperationId = this.selectedOperation.id;
        } else this.selectedOperation = this.operations.find(o => o.id === this.selectedOperationId);

        const observer = this.offers.length ?
            of(this.offers) :
            this.operationsService.getOffersByOperationId(this.selectedOperationId).pipe(tap(offers => this.offers = this.filterOffers(offers)));

        observer
            .pipe(
                tap(() => {
                    // If offerId provided, select offer
                    if (this.selectedOfferId && this.offers.length) {
                        this.selectedOffer = this.offers.find(o => Number(o.id) === this.selectedOfferId && o.operationId === this.selectedOperation?.id);
                        this.selectedOfferId = null;
                    }
                }),
                switchMap(() => this.initCredits()),
                finalize(() => this.isLoadingOffers = false)
            )
            .subscribe(() => {
                // If only 1 offer, select it by default
                if (this.offers.length === 1) this.sendOperationAndOffer(this.offers[0]);
                else if (this.selectedOffer) this.sendOperationAndOffer(this.selectedOffer);
                else this.sendOperationAndOffer(null);
            });
    }

    sendOperationAndOffer(offer: any): void {
        this.selectedOperation = this.operations.find(o => o.id === this.selectedOperationId);
        this.offerEvent.emit(offer);
        this.operationEvent.emit(this.selectedOperation);
        if (this.selectedOperation?.budget === 1) this.canRewardWithCreditEvent.emit(this.hasEnoughCredits);
        else this.canRewardWithCreditEvent.emit(true);
    }

    updateOffers() {
        this.isLoadingOffers = true;
        this.operationsService.getOffersByOperationId(this.selectedOperationId)
            .subscribe((offers) => {
                this.offers = this.filterOffers(offers);

                // If only 1 offer, select it by default
                if (this.offers.length === 1 && this.offers[0].status === 'active') this.sendOperationAndOffer(this.offers[0]);
                else this.sendOperationAndOffer(null);
            });
    }

    private resetOperationsStatus() {
        // Reset operation status to active if disabled for previous selected consumer
        if (this.operations.find((operation) => operation.status === 'disabled')) {
            this.operations.forEach((operation) => operation.status = 'active');
        }
        // Reset offer status to active if disabled for previous selected consumer
        if (this.offers.find((offer) => offer.status === 'disabled')) {
            this.offers.forEach((offer) => offer.status = 'active');
        }
    }

    initCredits(): Observable<[Credits[], CreditsHistory[]]> {
        // If this is an operation with a budget, find the matching credit for the user
        if (!this.selectedOperation?.budget || !this.selectedOffer) return of(null);
        this.budget = this.giftService.budgets.find(b => b.operationId === this.selectedOperation?.id);
        // If no budget is found, we consider the user is allowed to reward
        if (!this.budget) return of(null);
        return forkJoin([
            this.msServicesGiftService.getAllCredits(),
            this.msServicesGiftService.getCreditsHistoryByBudgetId(this.budget.id)
        ])
            .pipe(
                tap(([credits, creditHistories]) => {
                    this.creditsHistories = creditHistories.filter((ch) => ch.amount > 0);
                    this.credit = credits.find(c => c.budgetId === this.budget.id);
                    this.hasEnoughCredits = this.credit ? this.credit.amountRemaining >= this.selectedOffer.amount : false;
                    this.confirmHasEnoughCredits(credits.filter(s => s.budgetId === this.budget.id));
                }),
                catchError(() => of(null))
            );
    }

    getEntityName(): string {
        const budget = this.giftService.budgets.find(b => b.operationId === this.selectedOperation?.id);
        return this.giftService.hierarchicalLevels.find(hl => hl.id === budget.levelId).entity;
    }

    private filterOffers(offers: Offer[]): Offer[] {
        return offers
            // Get only active offer
            .filter(o => o.status === 'active')
            .sort((a, b) => a.amount === b.amount ? a.name.localeCompare(b.name) : (Number(a.amount) - Number(b.amount)));
    }

    private confirmHasEnoughCredits(value: Credits[]): void {
        this.hasExpiredInAmount = value.some(credit => {
            const creditHistory: CreditsHistory[] = this.creditsHistories.filter((ch) => ch.creditId === credit.id);
            if (!creditHistory) return false;
            const amountGranted = creditHistory.reduce((curr, prev) => curr + prev.amount, 0);
            // If the budget is expired in amount & the selected operation is active, hide the text
            return amountGranted >= this.budget.amountTotal && this.selectedOperation.status === 'active';
        });
    }
}
