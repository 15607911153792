<div class="w-100 mt-5 d-flex flex-column">

    <div class="col-12 mx-auto mt-3">
        <mat-form-field>
            <mat-label>Opérations :</mat-label>
            <mat-select (selectionChange)="updateOffers()" [(ngModel)]="selectedOperationId" data-cy="operations">
                @for (operation of operations; track operation.id) {
                    <mat-option [disabled]="operation.status === 'disabled'"
                                [value]="operation.id"
                                data-cy="option">
                        <span>{{operation.name}} </span>
                        @if (operation.endDate) {
                            <span>
                                - jusqu'au {{ (operation.endDate | dateBefore: 1) | date:'dd/MM/yyyy' }}
                            </span>
                        }
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
    @if (!isLoadingOffers) {
        <div class="col-12 mx-auto my-3 d-flex flex-column justify-content-center text-center">
            @for (offer of offers; track offer.id) {
                <div class="my-2 d-flex">
                    <input (change)="sendOperationAndOffer(offer)"
                           [disabled]=""
                           [checked]="offers.length === 1 || offer.id === selectedOffer?.id"
                           [value]="offer"
                           class="mx-2 my-auto check-box"
                           id="{{offer.id}}"
                           name="offer"
                           type="radio">
                    <label for="{{offer.id}}" class="clickable check-box">{{offer.name}} - {{offer.amount}}€</label>
                </div>
            }
        </div>
        @if (selectedOperation?.description) {
            <div class="bg-dark-blue text-white p-3">
                <div [innerHTML]="selectedOperation.description"></div>
            </div>
        }
        @if (selectedOperation?.budget && credit && hasEnoughCredits) {
            <div class="mt-5 p-3">
                À ce jour, le budget Récompenses disponible pour votre {{ getEntityName() }} s’élève à
                {{ credit.amountRemaining | currency:'EUR':'symbol':'1.0-0':'fr-FR' }}
                @if (budget.endAt) {
                    <span>
                        à utiliser avant le {{ budget.endAt | date:'dd/MM/yyyy' }}
                    </span>
                }
            </div>
        }
        @if (selectedOperation?.budget && selectedOffer && hasExpiredInAmount) {
            <div class="mt-5 p-3">
                Le quota de récompenses pour cette opération a été épuisé.
            </div>
        }
    } @else {
        <div class="text-center my-5 fs-2">
            <em class="fas fa-spinner fa-spin"></em>
        </div>
    }
</div>
