import {Component, DestroyRef, ViewChild} from '@angular/core';
import {GiftUser, HierarchicalLevel, MsCoreService, MsServicesGiftService, User, UserCached, UserService} from '@isifid/core';
import {MatSort} from '@angular/material/sort';
import {GiftService} from '../../../shared/services/gift.service';
import {catchError, finalize, forkJoin, map, Observable, of, switchMap, take} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {GiftUserService} from '../../../shared/services/gift-user.service';
import { DialogUpdateBranchCodeComponent } from '../dialog-update-branch-code/dialog-update-branch-code.component';
import { DialogAddAdvisorComponent } from '../dialog-add-advisor/dialog-add-advisor.component';
import { DialogUpdateAdvisorComponent } from '../dialog-update-advisor/dialog-update-advisor.component';
import { DialogConfirmationComponent } from 'src/app/shared/components/dialog-confirmation/dialog-confirmation.component';

@Component({
  selector: 'app-account-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent {
    branchList = [];
    isManagerAccountCompleteAtLoading: boolean;
    dataSource = new MatTableDataSource<any>();
    displayedColumns: Array<string> = ['branchCode', 'firstName', 'lastName', 'email', 'lastLoggedInAt', 'actions'];
    hideTable: boolean = true;
    loading: boolean;
    user: UserCached;
    private advisorLevelId: number = -1;
    private giftUser: GiftUser;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private readonly dialog: MatDialog,
        private readonly giftService: GiftService,
        private readonly giftUserService: GiftUserService,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly msCoreService: MsCoreService,
        public readonly userService: UserService,
        private readonly destroyRef: DestroyRef
    ) {
        this.isManagerAccountCompleteAtLoading = giftService.isManagerAccountComplete;
        this.init();
    }

    openUpdatingBranchCodeDialog(): void {
        const dialogRef = this.dialog.open(
            DialogUpdateBranchCodeComponent,
            {
                maxWidth: 650,
                panelClass: 'dialog-panel',
                data: {
                    branchCodes: this.branchList
                }
            }
        );
        dialogRef.afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(isUpdated => {
                if (!!isUpdated) this.init();
            });
    }

    openAddingAdvisorDialog(): void {
        const dialogRef = this.dialog.open(
            DialogAddAdvisorComponent,
            {
                maxWidth: 650,
                panelClass: 'dialog-panel',
                data: {
                    branchCodes: this.branchList,
                    users: this.dataSource.data,
                }
            }
        );
        dialogRef.afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(isUpdated => {
                if (!!isUpdated) this.init();
            });
    }

    openUpdatingAdvisorDialog(user?, index?): void {
        const dialogRef = this.dialog.open(
            DialogUpdateAdvisorComponent,
            {
                maxWidth: 650,
                panelClass: 'dialog-panel',
                data: {
                    branchCodes: this.branchList,
                    advisor: user
                }
            }
        );
        dialogRef.afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(advisor => {
                if (!!advisor) {
                    const data = [...this.dataSource.data];
                    data.splice(index, 1, advisor);
                    this.dataSource.data = data;
                }
            });
    }

    remove(user): void {
        this.loading = true;
        const giftUser: GiftUser = user.giftUser;
        const branchIndex = giftUser.branchList.findIndex(s => parseInt(user.branchCode) === s);
        giftUser.branchList.splice(branchIndex, 1);

        const dialogRef = this.dialog.open(
            DialogConfirmationComponent,
            {
                data: {
                    title: 'Supprimer le conseiller',
                    content: `Êtes-vous sûr(e) de supprimer '${user.firstName}  ${user.lastName}' de votre agence ?`
                }
            }
        );
        dialogRef.afterClosed()
            .pipe(
                finalize(() => this.loading = false),
                takeUntilDestroyed(this.destroyRef),
                switchMap(s => {
                    return !!s
                        ? this.msServicesGiftService.updateGiftUser({id: giftUser.id, uuid: giftUser.uuid, branchList: giftUser.branchList})
                        : of(null);
                })
            )
            .subscribe(s => {
                if (!!s) this.init();
            });
    }

    private init(): void {
        this.loading = true;
        this.user = this.userService.getUser();
        this.advisorLevelId = this.giftService.hierarchicalLevels.find(s => s.role === 'GIFT_ADVISOR')?.id ?? -1;
        this.giftUserService.getGiftUser().pipe(take(1)).subscribe(s => this.giftUser = s);
        this.initBranchAndUsers();
    }

    private initBranchAndUsers(): void {
        if (!this.giftUser?.branchList?.length) return;
        this.branchList = this.giftUser.branchList.map(s => this.giftService.convertBranchCodeToString(s));

        forkJoin(this.branchList.map(s => this.getGiftUsersByBranchCode(s)))
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                finalize(() => this.loading = false)
            )
            .subscribe({
                next: users => {
                    const data = users.flat(1).filter(user => user);
                    this.dataSource = new MatTableDataSource<unknown>(data);
                    this.dataSource.sort = this.sort;

                    if (this.branchList.length) {
                        // Custom paginator
                        this.paginator._intl.itemsPerPageLabel = 'Conseillers par page';
                        this.dataSource.paginator = this.paginator;
                        this.hideTable = false;
                    }
                }
            });
    }

    private getGiftUsersByBranchCode(branchCode): Observable<User[]> {
        return this.msServicesGiftService.getGiftUsersByBranchCode(branchCode)
            .pipe(
                switchMap(users => {
                    // Don't display manager in its team
                    users = users.filter(u => u.uuid !== this.user.uuid);
                    if (!users.length) return of([null]);
                    return forkJoin(
                        users.map(u =>
                            this.msCoreService.getUser(u.uuid)
                                .pipe(
                                    map(user => (
                                        (u.levelId as unknown as number) !== this.advisorLevelId
                                            ? null
                                            : {
                                                giftUser: u,
                                                uuid: user.uuid,
                                                firstName: user.firstName,
                                                lastName: user.lastName,
                                                email: user.email,
                                                branchCode: branchCode,
                                                lastLoggedInAt: user.lastLoggedInAt,
                                                editable: this.branchList.some(s => !u.branchList.includes(parseInt(s)))
                                            }
                                    )),
                                    catchError(() => of(null))
                                )
                        )
                    );
                }),
                catchError(() => of([null]))
            )
    }

}
