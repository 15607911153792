import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormValidators, GiftUser, MsServicesGiftService } from '@isifid/core';
import { finalize} from 'rxjs';

interface DialogDataAdvisor {
  giftUser: GiftUser,
  uuid: string
  firstName: string
  lastName: string
  email: string
  branchCode: string
  lastLoggedInAt: string
}
interface DialogData {
  branchCodes: string[];
  advisor: DialogDataAdvisor
}

@Component({
  selector: 'app-dialog-update-advisor',
  templateUrl: './dialog-update-advisor.component.html',
  styleUrl: './dialog-update-advisor.component.scss'
})
export class DialogUpdateAdvisorComponent {
private msServicesGiftService = inject(MsServicesGiftService);
  private formValidators = inject(FormValidators);
  private destroyRef = inject(DestroyRef);
  data: DialogData = inject(MAT_DIALOG_DATA);

  accessSent: boolean = false;
  loading: boolean = false;
  advisor: DialogDataAdvisor;
  formGroup = new FormGroup({
    email: new FormControl({value: this.data.advisor.email, disabled: true}, [Validators.required, Validators.email, this.formValidators.isEmail, Validators.pattern('^[^0-9]+[0-9]{0,1}(@){1}[^0-9]+')]),
    branchCode: new FormControl(this.data.advisor.branchCode, [Validators.required])
  });

  isDisabledBranchCode(value: string): boolean {
    return this.data.advisor.giftUser.branchList.includes(parseInt(value));
  }

  submit(): void {
    if (this.loading || this.formGroup.invalid || this.data.advisor.branchCode === this.formGroup.get('branchCode').value) return;
    this.loading = true;
    const giftUser = this.data.advisor.giftUser;
    const branchIndex = giftUser.branchList.findIndex(s => parseInt(this.data.advisor.branchCode) === s);
    giftUser.branchList.splice(branchIndex, 1, parseInt(this.formGroup.get('branchCode').value));

    this.msServicesGiftService.updateGiftUser(giftUser)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => this.loading = false)
      )
      .subscribe(() => {
        this.accessSent = true;
        this.advisor = {
          ...this.data.advisor,
          branchCode: this.formGroup.get('branchCode').value,
          giftUser: giftUser
        }
      });
  }
}
