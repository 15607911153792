<div class="container-fluid m-0 text-center">
    @if (userService.hasRole('GIFT_MANAGER', user)) {
        <div class="mt-5 text-start">
            @if (branchList.length > 1) {
                Je suis actuellement rattaché(e) aux agences:
                @for (branchCode of branchList; track $index) {
                    @if ($index > 0) { , }
                    {{branchCode}}
                }
            } @else {
                Je suis actuellement rattaché(e) a l'agence: {{branchList[0]}}
            }
        </div>
        <div class="text-start">
            <button (click)="openUpdatingBranchCodeDialog()" [disabled]="loading" class="btn btn-validate mt-2">
                Mettre à jour
            </button>
        </div>
    }

    <div class="mt-5 mb-4 d-flex justify-content-between w-100">
        <h2>Mon équipe</h2>
        <div>
            <button class="btn btn-validate mt-2" [disabled]="loading" (click)="openAddingAdvisorDialog()">
                Ajouter un conseiller
            </button>
        </div>
    </div>

    <!-- Table --------------------------------------------------------------------------------------------- -->
    <div class="responsive-table">
        <div class="responsive-table-body">
            <table [dataSource]="dataSource" [hidden]="hideTable" aria-describedby="mystaff" class="mb-1"
                   mat-table matSort>
        
                <ng-container class="py-5" matColumnDef="branchCode">
                    <th *matHeaderCellDef id="branchCode" mat-header-cell mat-sort-header>Code PDV</th>
                    <td *matCellDef="let user" class="text-grey" mat-cell>
                        {{user.branchCode}}
                    </td>
                </ng-container>
        
                <ng-container class="py-5" matColumnDef="firstName">
                    <th *matHeaderCellDef id="firstName" mat-header-cell mat-sort-header>Prénom</th>
                    <td *matCellDef="let user" class="text-grey" mat-cell>
                        {{user.firstName}}
                    </td>
                </ng-container>
        
                <ng-container class="py-5" matColumnDef="lastName">
                    <th *matHeaderCellDef id="lastName" mat-header-cell mat-sort-header>Nom</th>
                    <td *matCellDef="let user" class="text-grey" mat-cell>
                        {{user.lastName}}
                    </td>
                </ng-container>
        
                <ng-container class="py-5" matColumnDef="email">
                    <th *matHeaderCellDef id="email" mat-header-cell mat-sort-header>Email</th>
                    <td *matCellDef="let user" class="text-grey" mat-cell>
                        {{user.email}}
                    </td>
                </ng-container>
        
                <ng-container class="py-5" matColumnDef="lastLoggedInAt">
                    <th *matHeaderCellDef id="lastLoggedInAt" mat-header-cell mat-sort-header>Dernière connexion</th>
                    <td *matCellDef="let user" class="text-grey" mat-cell>
                        {{user.lastLoggedInAt | date: 'shortDate':'+2':'fr'}}
                    </td>
                </ng-container>
                
                <ng-container class="py-5" matColumnDef="actions">
                    <th *matHeaderCellDef id="actions" mat-header-cell mat-sort-header></th>
                    <td *matCellDef="let user; let i = index" class="text-grey" mat-cell>
                        <button class="btn btn-selected my-1 me-1" [disabled]="loading || !user.editable" (click)="openUpdatingAdvisorDialog(user, i)">
                            Modifier
                        </button>
                        <button class="btn btn-selected my-1" [disabled]="loading" (click)="remove(user)">
                            Supprimer
                        </button>
                    </td>
                </ng-container>
        
        
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;"
                    mat-row>
                </tr>
            </table>
        </div>
        <mat-paginator
            [hidden]="hideTable || dataSource.data.length < 25"
            [pageSizeOptions]="[25, 50, 100]"
            aria-label="Select page of user"
            showFirstLastButtons
        />
    </div>
</div>
