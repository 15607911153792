<mat-toolbar class="pe-5 header">

    <div class="col g-0">
        <div class="row">
            <div class="col g-0">
                <a class="mx-0 mx-md-2 mx-lg-3" (click)="goToHome()">
                    <img src="{{logoUrl}}" [ngClass]="canGoToHome()? '' : 'cursor-default'" alt="logo" id="logo" height="" width="">
                </a>
            </div>

            <div class="col g-0">
                @if (userService.hasRole('GIFT_HQ')) {
                    <div class="d-none d-md-flex justify-content-center align-items-center mx-auto toggleHq">
                        <span [ngClass]="isAdminUrl() ? 'btn-selected' : 'btn-transparent text-purple'"
                              routerLink="/admin/dashboard">Direction</span>
                        <span [ngClass]="!isAdminUrl() ? 'btn-selected' : 'btn-transparent text-purple'"
                              (click)="goToHome()">Conseiller </span>
                    </div>
                }
            </div>
        </div>
    </div>

    <p class="col-3 d-none d-lg-flex flex-column mt-5 text-center">
        <span class="gift-logo fw-bold" [ngClass]="canGoToHome()? 'clickable' : 'cursor-default'" (click)="goToHome()">Gift
            <img alt="cross" height="16" src="assets/icons/cross.svg" width="16">
        </span>
        <span class="mt-3 text-purple pleasure" [ngClass]="canGoToHome()? 'clickable' : 'cursor-default'" (click)="goToHome()">Le plaisir de faire plaisir</span>
    </p>

    <div class="col text-end d-flex flex-row justify-content-end align-items-center">
        @if (user) {
            <div [matMenuTriggerFor]="accountMenu" class="d-flex align-items-center clickable">
                <div>
                    @if (user.firstName) {
                        <span class="d-none d-xl-inline-block me-3 mt-auto email ">Bonjour {{ user.firstName }}</span>
                        <br>
                    }
                    @if (branchCodes.length === 1) {
                        <span class="d-none d-xl-inline-block me-3 mt-auto email ">Mon code agence : {{ branchCodes[0] }}</span>
                    }
                </div>

                <img [hidden]="!giftService.isAccountComplete"
                     data-cy="account-menu"
                     alt="button for user menu"
                     class="clickable accountMenu"
                     height="45"
                     src="assets/icons/user-circle.svg" width="35">
            </div>
        }

        @if (user && giftService.isAccountComplete && this.path !== '/account/favorite?force=true') {
            <i (click)="toggleSidenav()"
               aria-label="menu"
               aria-hidden="true" class="ms-4 me-2 fas fa-bars clickable">
            </i>
        }
    </div>
</mat-toolbar>

<mat-menu #accountMenu="matMenu" xPosition="before">
    @if (giftService.isAccountComplete && this.path !== '/account/favorite?force=true') {
        <button mat-menu-item
                routerLink="/account/favorite"
                [queryParams]="{'force': false}">
            Enregistrer&nbsp;lien&nbsp;dans&nbsp;les&nbsp;favoris
        </button>

        @if (sponsorshipService.hasSponsorshipOnline && !userService.hasRole('GIFT_HQ')) {
            <button mat-menu-item routerLink="/sponsorship/qr-code">
                Mon QR code personnel
            </button>
        }

        @if (branchCodes.length > 0) {
            <button mat-menu-item (click)="openUpdatingBranchCodeDialog()">
                @if (branchCodes.length > 1) {
                    Mes codes agences
                } @else {
                    Mon code agence
                }
            </button>
        }

        @if (!userService.hasRole('GIFT_HQ')) {
            <button mat-menu-item routerLink="/account/user">
                Mes informations
            </button>
        }

        @if (userService.hasRole('GIFT_MANAGER')) {
            <button mat-menu-item routerLink="/account/team">
                Mon équipe
            </button>
        }

    }

    @if (user?.firstName && (userService.hasRole('GIFT_ADVISOR') || userService.hasRole('GIFT_MANAGER'))) {
        <button mat-menu-item
                (click)="openPersonalLinkDialog()">
            Vous n'êtes pas {{ user?.firstName }} ?
        </button>
    }

    <button mat-menu-item routerLink="/logout">
        Déconnexion
    </button>
</mat-menu>

