@if (!form) {
    <div class="fa-2x text-center">
        <em class="fas fa-sync fa-spin"></em>
    </div>
} @else {
    <div class="container px-5 mx-auto text-center">
        <div class="row">
            <div class="col">
                <h3>Parrainage valide ! Complétez les données du filleul.</h3>
            </div>
        </div>
    </div>

    <form (ngSubmit)="submit()" [formGroup]="form"
          class="container mt-5 px-5 mx-auto text-left">

        <div class="d-flex flex-row row mt-2">
            <div class="mx-auto col text-center">
                <mat-form-field appearance="fill" class="my-3">
                    <mat-label>N° de portable de votre client</mat-label>
                    <input matInput
                           (change)="formatPhone()"
                           id="mobile"
                           formControlName="mobile"
                           type="tel"/>
                </mat-form-field>
            </div>

            @if (!giftService.getConfigurationValue('simpleReward')) {
                <div class="col my-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Civilité</mat-label>
                        <mat-select formControlName="gender" data-cy="gender">
                            <mat-option value="1" data-cy="M">M.</mat-option>
                            <mat-option value="2" data-cy="Mme">Mme</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            }
        </div>

        <ng-container>
            @if (!giftService.getConfigurationValue('simpleReward')) {
                <div class="row my-2">
                    <mat-form-field appearance="fill" class="col my-3">
                        <mat-label>Nom</mat-label>
                        <input matInput
                               (blur)="capitalizeInput($event)"
                               formControlName="lastName"
                               id="lastName"
                               type="text"/>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="my-3 col">
                        <mat-label>Prénom</mat-label>
                        <input matInput
                               (blur)="capitalizeInput($event)"
                               formControlName="firstName"
                               id="firstName"
                               type="text"/>
                    </mat-form-field>
                </div>
            }

            <div class="row my-2">
                <mat-form-field appearance="fill" class="col my-3">
                    <mat-label>Email</mat-label>
                    <input matInput
                           formControlName="email"
                           id="email"
                           type="email"
                           (blur)="lowerCaseInput($event)"/>
                </mat-form-field>
            </div>

            @if (giftService.getConfigurationValue('externalIdRequiredForSponsored')) {
                <div class="row my-2">
                    <mat-form-field appearance="fill" class="my-3">
                        <mat-label>
                            @if (giftService.giftNetworkVariables?.idName) {{{giftService.giftNetworkVariables.idName | titlecase }}}
                            @else {ID * à {{giftService.giftNetworkVariables?.idLength || 30}} chiffres)}
                        </mat-label>
                        <input matInput
                               [maxLength]="giftService.giftNetworkVariables?.idLength || 30"
                               formControlName="externalId"
                               id="externalId"
                               type="text"/>
                    </mat-form-field>
                </div>
            }

            <div class="row my-2">
                <div class="col">
                    <mat-form-field appearance="fill" class="my-3">
                        <mat-label>Né(e) le</mat-label>
                        <input matInput formControlName="birthDate" id="birthDate"
                               [max]="maxDate"
                               [min]="minDate"
                               [matDatepicker]="birthDate"/>
                        <mat-datepicker-toggle matIconSuffix [for]="birthDate" />
                        <mat-datepicker #birthDate />
                        <mat-error>
                            Date de naissance invalide.<br>
                            @if (form.get('birthDate').hasError('dateMax') && sponsorshipService.settings.sponsorAgeMin) {
                                <span>
                                    Age minimum {{sponsorshipService.settings.sponsorAgeMin}} ans.
                                </span>
                            }
                            @if (form.get('birthDate').hasError('dateMin') && sponsorshipService.settings.sponsorAgeMax) {
                                <span>
                                    Age maximum {{sponsorshipService.settings.sponsorAgeMax}} ans.
                                </span>
                            }
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            @if (sponsorshipService.settings.professionalEnabled) {
                <div class="row mt-4">
                    <mat-form-field appearance="fill" class="col">
                        <mat-label>Civilité</mat-label>
                        <mat-select formControlName="type"
                                    data-cy="pro-select"
                                    (selectionChange)="updateSponsoredOffer($event)">
                            <mat-option value="">Particulier</mat-option>
                            <mat-option value="pro" data-cy="pro">Professionnel</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            }
            @for (custom of customRewardFields; track index; let index = $index) {
                <div>
                    @if (custom.type === 'link') {
                        <div class="row my-2">
                            <div class="col">
                                @if (custom.placeholder) {<p>{{custom.placeholder}}</p>}
                                <a [href]="custom.defaultValue" target="_blank">{{custom.name}}</a>
                            </div>
                        </div>
                    } @else if (custom.type !== 'checkbox' && custom.type !== 'link') {
                        <div class="row my-2">
                            <div class="col">
                                <mat-form-field appearance="fill" class="col my-3">
                                    <mat-label>{{custom.name}}</mat-label>
                                    <input matInput
                                           [formControlName]="custom.name"
                                           [class.not-empty]="form.controls[custom.name].value"
                                           [required]="custom.required === 'true'"
                                           [type]="custom.type === 'email' ? 'email' : 'text'"
                                           [id]="custom.name + custom.order"
                                           [value]="custom.defaultValue.toString()"/>
                                    <mat-error>{{getErrorMessage(custom.name)}}</mat-error>
                                    @if (custom.hint) {<mat-hint>{{custom.hint}}</mat-hint>}
                                </mat-form-field>
                            </div>
                        </div>
                    } @else if (custom.type === 'checkbox') {

                        <div class="row my-2">
                            <div class="col">
                                <input [formControlName]="custom.name"
                                       id="{{custom.name + custom.order}}"
                                       required="{{custom.required === true}}"
                                       type="checkbox">
                                <label class="ms-2 check-box" for="{{custom.name + custom.order}}">{{custom.name}}</label>
                                <br>
                                @if (custom.hint) {<p class="text-center">{{custom.hint}}</p>}
                                @if (form.get([custom.name]).invalid && form.get([custom.name]).touched) {
                                    <p class="text-red">
                                        {{getErrorMessage(custom.name)}}
                                    </p>
                                }
                            </div>
                        </div>
                    }
                </div>
            }

            @if (giftService.getConfigurationValue('externalIdRequiredForSponsor') && checkExternalId(sponsor.externalId)) {
                <div class="row my-2">
                    <h3 class="mb-4"> Complétez les données du parrain : </h3>
                    <mat-form-field appearance="fill" class="col my-3">
                        <mat-label>
                            @if (giftService.giftNetworkVariables?.idName) {
                                {{giftService.giftNetworkVariables?.idName | titlecase }}
                            } @else {
                                ID * à {{giftService.giftNetworkVariables?.idLength || 30}} chiffres)
                            }
                        </mat-label>
                        <input matInput
                               [maxLength]="giftService.giftNetworkVariables?.idLength || 30"
                               formControlName="sponsorExternalId"
                               id="sponsorExternalId"
                               type="text"/>
                    </mat-form-field>
                </div>
            }

            @if (branchList.length > 1) {
                <mat-form-field appearance="fill" class="col">
                    <mat-label>Merci de sélectionner votre code agence ou point de vente</mat-label>
                    <mat-select formControlName="branchCode">
                        @for (branch of branchList; track branch) {
                            <mat-option value="{{branch}}">
                                {{giftService.convertBranchCodeToString(branch)}}
                            </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            }

            @if (mortgageLoanConfig) {
                <mat-checkbox formControlName="mortgageLoan">
                    Mon client a souscrit un crédit immobilier.
                </mat-checkbox>
            }

            @if (selfCareConfig && (!selfCareAllowedBranchesConfig || (selfCareAllowedBranchesConfig && branchIsAllowedForSelfCare))) {
                <mat-checkbox formControlName="selfCare">
                    Mon client vient du parcours de parrainage digital.
                </mat-checkbox>
            }

            <div class="row mt-4">
                <div class="col">
                    @if (!loading) {
                        <button class="btn btn-validate mt-2"
                                data-cy="submit"
                                name="button"
                                type="submit">
                            Valider
                        </button>
                    } @else {
                        <button class="btn btn-validate mt-2" disabled>
                            <i aria-hidden="true" class="fas fa-spinner fa-spin"></i>
                        </button>
                    }
                </div>
            </div>
        </ng-container>
    </form>
}


