<span class="dialog-panel-close" mat-dialog-close>&times;</span>
<mat-card class="p-0">
    <mat-card-header class="px-4 px-lg-5 pt-5 pb-3 text-white justify-content-center">
        <mat-card-title class="text-center fw-bold big pb-2">
            Mettre à jour un conseiller de mon équipe
        </mat-card-title>
    </mat-card-header>
    @if (loading) {
        <mat-card-content class="p-5">
            <div class="py-5 my-4">
                <mat-spinner class="mx-auto" />
            </div>
        </mat-card-content>
    } @else if (accessSent && !loading) {
        <mat-card-content class="p-5">
            <h2 class="text-dark py-4 my-5 text-align-justify">
                Votre conseiller a été mis à jour avec succès.
            </h2>
            <div class="row justify-content-center mt-4 mb-5">
                <div class="col text-center">
                    <button class="btn btn-black mt-2 text-nowrap" data-cy="close-dialog" [mat-dialog-close]="advisor">
                        Fermer
                    </button>
                </div>
            </div>
        </mat-card-content>
    } @else if (!accessSent && !loading) {

        <mat-card-content class="px-3 py-5">
            <form (ngSubmit)="submit()" [formGroup]="formGroup"
                  class="container mb-5 px-5 mx-auto text-center">
                <div class="row my-3 mx-3">
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Email</mat-label>
                            <input matInput
                                   maxlength="60"
                                   class="text-center"
                                   formControlName="email"
                                   id="email"
                                   type="text"/>
                        </mat-form-field>

                        @if (data.branchCodes.length > 1) {
                            <mat-form-field class="mt-3">
                                <mat-label>Sélectionnez le code PDV</mat-label>
                                <mat-select formControlName="branchCode">
                                    @for (branchCode of data.branchCodes; track $index) {
                                        <mat-option
                                            [value]="branchCode"
                                            [disabled]="isDisabledBranchCode(branchCode)">
                                            {{branchCode}}
                                        </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        }
                    </div>
                </div>

                <div class="row mt-4 mb-5">
                    <div class="col">
                        <button class="btn btn-black mt-2 text-nowrap"
                                data-cy="submit"
                                name="button"
                                type="submit"
                                [disabled]="data.advisor.branchCode === formGroup.get('branchCode').value">
                                Ajouter
                        </button>
                    </div>
                </div>
            </form>
        </mat-card-content>
    }
</mat-card>